import Swiper from 'swiper';
jQuery(document).ready(function ($) {
  var $body = $('body');
  var $window = $(window);
  var scrollPosition = $(window).scrollTop();
  function onLoad() {
    subMenu();
    removeMenu();
    openmenu();
    pageScroll();
    setTimeout(function () {
      $body.addClass('loaded');
      $window.trigger('resize');
      $window.trigger('scroll');
    }, 300);
  }

  //* SUBMENU ACTIONS DESTOP & MOBILE */
  function subMenu() {
    $('.nav-container li.menu-item-has-children > a').on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (!$(this).closest('li').hasClass('active')) {
        $('.nav-container li.menu-item-has-children').removeClass('active');
        $('.nav-container li.menu-item-has-children ul').removeClass('active');
        $body.addClass('no-scroll');
        $(this).closest('li').addClass('active');
        $(this).next('ul').addClass('active');
      } else {
        $('.nav-container li.menu-item-has-children').removeClass('active');
        $('.nav-container li.menu-item-has-children ul').removeClass('active');
        $body.removeClass('no-scroll');
      }
    });
    $('.mobile-menu li.menu-item-has-children > a').on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (!$(this).closest('li').hasClass('active')) {
        $('.mobile-menu li.menu-item-has-children').removeClass('active');
        $('.mobile-menu li.menu-item-has-children ul').slideUp();
        $(this).closest('li').addClass('active');
        $(this).next('ul').slideDown();
      } else {
        $('.mobile-menu li.menu-item-has-children').removeClass('active');
        $('.mobile-menu li.menu-item-has-children ul').slideUp();
      }
    });
  }
  function removeMenu() {
    $body.on('click', function () {
      $('.nav-container li.menu-item-has-children').removeClass('active');
      $('.nav-container li.menu-item-has-children ul').removeClass('active');
      $body.removeClass('no-scroll');
      $('.mobile-menu li.menu-item-has-children').removeClass('active');
      $('.mobile-menu li.menu-item-has-children ul').slideUp();
    });
  }

  //* BURGER */
  function openmenu() {
    $body.on('click', '.burger', function () {
      $body.toggleClass('menu-shown');
      $('.nav-container li.menu-item-has-children').removeClass('active');
      $('.nav-container li.menu-item-has-children ul').removeClass('active');
      $('.mobile-menu li.menu-item-has-children').removeClass('active');
      $('.mobile-menu li.menu-item-has-children ul').slideUp();
    });
  }

  //* PAGE SCROLL
  function pageScroll() {
    $('a.page-scroll, a[href^="#"]:not(.menu-item-has-children)').on('click', function () {
      var targetDiv = $(this).attr('href');
      $('html, body').animate({
        scrollTop: $(targetDiv).offset().top
      }, 100);
      return false;
    });
  }

  //* SCROLL DIRECTION DETECTOR
  function scrollDirection() {
    var distance = $(window).scrollTop();
    if (distance < 101) {
      $body.addClass('scrollUp');
    }
    if (distance > 100 && distance > scrollPosition) {
      $body.removeClass('scrollUp');
    } else if (distance < scrollPosition) {
      $body.addClass('scrollUp');
    }
    scrollPosition = distance;
  }

  //* inSight
  function isScrolledIntoView(elem) {
    var $docViewTop = $(window).scrollTop();
    var $docViewBottom = $docViewTop + $(window).height();
    var elemTop = $(elem).offset().top;
    return elemTop <= $docViewBottom;
  }
  function inSight() {
    if ($('.inSight').length) {
      $('.inSight').each(function () {
        var $this = $(this);
        if (isScrolledIntoView(this) === true) {
          $this.addClass('alive');
        } else {
          $this.removeClass('alive');
        }
      });
    }
  }

  //* CAROUSEL SWIPE
  if ($('.swiper.services').length) {
    var swiper = new Swiper('.swiper.services', {
      slidesPerView: 1.25,
      spaceBetween: 30,
      breakpoints: {
        640: {
          slidesPerView: 1.75
        },
        1024: {
          slidesPerView: 2.75
        }
      }
    });
  }

  //* ACCORDIONS
  if ($('.accordion').length) {
    $('.accordion').on('click', function () {
      if (!$(this).hasClass('active')) {
        $('.accordion').removeClass('active');
        $('.accordion').find('.answer').slideUp();
        $(this).addClass('active');
        $(this).find('.answer').slideDown();
      } else {
        $('.accordion').removeClass('active');
        $('.accordion').find('.answer').slideUp();
      }
    });
  }
  function viewport() {
    var e = window,
      a = 'inner';
    if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
    }
    return {
      width: e[a + 'Width'],
      height: e[a + 'Height']
    };
  }
  function giveSizeToOthers($context, $toResize, minWidth, maxWidth) {
    if ($context.length > 0) {
      if ($toResize.length > 0) {
        if (!minWidth) var minWidth = 0; // compatibilité IE
        if (!maxWidth) var maxWidth = 9999; // compatibilité IE
        if (viewport().width > minWidth && viewport().width < maxWidth) {
          $context.each(function () {
            var height = 0;
            var $objects = $(this).find('.' + $toResize.attr('class').split(' ')[0]);
            $objects.each(function () {
              $objects.height('');
              var currentHeight = $(this).children('.resize').outerHeight();
              if (currentHeight > height) height = currentHeight;
            });
            $objects.children('.resize').parent().height(height);
          });
        } else {
          $toResize.height('');
        }
      }
    }
  }

  //* on SCROLL EVENTS
  function onScroll() {
    scrollDirection();
    inSight();
  }
  $window.on('scroll', function () {
    onScroll();
  });

  //* on RESIZE EVENTS
  $window.on('resize', function () {
    giveSizeToOthers($('.cards'), $('.adjustHeight'), 480);
  });
  onLoad();
});